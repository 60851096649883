import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import iTunesLogo from '../images/stores/itunes-pod.png'
import spotifyLogo from '../images/stores/spotify-pod.png'
import tuneInLogo from '../images/stores/tunein.png'
import stitcherLogo from '../images/stores/stitcher.png'
import youtubeLogo from '../images/stores/youtube.png'


const Podcast = () => (
 <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">you can experience it all now without leaving this page</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container podcast is-fluid">
        <h2 className="is-size-4">Introducing Planet and Sky, the deeper story &ndash; a podcast in nine episodes</h2>
        <div className="sources">
          <a href="https://itunes.apple.com/us/podcast/something-is-dreaming/id1445329229?i=1000424937487&mt=2" target="_blank"><img src={iTunesLogo} className="itunes-pod" alt="Listen on iTunes" /></a>
          <a href="https://open.spotify.com/show/3Ec7l2wEU4X40W5LWcNQMb" target="_blank"><img src={spotifyLogo} className="itunes-pod" alt="Listen on Spotify" /></a>
          <a href="https://tunein.com/podcasts/Arts--Culture-Podcasts/Planet-and-Sky-The-Deeper-Story-p1183230/" target="_blank"><img src={tuneInLogo} className="itunes-pod" alt="Listen on Tunein" /></a>
          <a href="https://www.stitcher.com/s?fid=348467" target="_blank"><img src={stitcherLogo} className="itunes-pod" alt="Listen on Sticher" /></a>
          <a href="https://www.youtube.com/playlist?list=PLOP60Wa94JXEIH869tQzFRFdLcAnRT5Sh" target="_blank"><img src={youtubeLogo} className="itunes-pod" alt="Listen on YouTube" /></a>
        </div>

        <h3 className="is-size-5">Act One</h3>
        <ul>
          <li>
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/7024571/height/150/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="150" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
            <Link to="/episodes/episode1">Transcript</Link>&nbsp;&nbsp;&nbsp;<Link to="/songs/something_is_dreaming">Lyrics</Link>
          </li>
          <li>
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/7764503/height/150/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="150" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
            <Link to="/episodes/episode2">Transcript</Link>&nbsp;&nbsp;&nbsp;<Link to="/songs/round">Lyrics</Link>
          </li>
          <li>
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/7764584/height/150/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="150" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
            <Link to="/episodes/episode3">Transcript</Link>&nbsp;&nbsp;&nbsp;<Link to="/songs/travelers">Lyrics</Link>
          </li>
        </ul>

        <h3 className="is-size-5">Act Two</h3>
        <ul>
          <li>
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8071292/height/150/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="150" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
            <Link to="/episodes/episode4">Transcript</Link>&nbsp;&nbsp;&nbsp;<Link to="/songs/eons_of_joy">Lyrics</Link>
          </li>
          <li>
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8093711/height/150/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="150" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
            <Link to="/episodes/episode5">Transcript</Link>&nbsp;&nbsp;&nbsp;<Link to="/songs/such_is_love">Lyrics</Link>
          </li>
          <li>
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8101526/height/150/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="150" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
            <Link to="/episodes/episode6">Transcript</Link>&nbsp;&nbsp;&nbsp;<Link to="/songs/beautiful_life">Lyrics</Link>
          </li>
        </ul>

        <h3 className="is-size-5">Act Three</h3>
        <ul>
          <li>
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8188427/height/150/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="150" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
            <Link to="/episodes/episode7">Transcript</Link>&nbsp;&nbsp;&nbsp;<Link to="/songs/planet_my_love">Lyrics</Link>
          </li>
          <li>
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8259038/height/150/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="150" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
            <Link to="/episodes/episode8">Transcript</Link>&nbsp;&nbsp;&nbsp;<Link to="/songs/empty_world">Lyrics</Link>
          </li>
          <li>
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8362217/height/150/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="150" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
            <Link to="/episodes/episode9">Transcript</Link>&nbsp;&nbsp;&nbsp;<Link to="/songs/wake_up">Lyrics</Link>
          </li>
        </ul>

      </div>
    </section>
  </Layout>
)

export default Podcast
